@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap');

* {
  padding: 0%;
  margin: 0%;
  font-family: 'Source Sans Pro', sans-serif;
}

.main-warning {
  margin-inline: 0.8rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50% !important;
  width: 100%;
  z-index: 99999;
}

.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.nav-link {
  color: #ffff !important;
}
.error-container{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.error-container h1{
  font-size: 7.2rem;
}
.img-responsive {
  width: 200px;
  margin-top: -0.3125rem;
}

.main-container {
  margin-top: 16px;
  flex-grow: 1;

}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: white;
}

.footer-area {
  background: #0d6efd;
  text-align: center;
  margin: auto;
  color: #fff;
}

#map {
  width: 100%;
  height: 100%;

}

.map-container {
  position: relative;
}

.detail-header {
  color: #e67e22;
  font-weight: 650;
}

.list-item {
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #cecdcd;
}

body:has(#fav-check:checked) .list-item:not(:has(.fa-solid.fa-star)) {
  display: none;
}

.btn-fav {
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
}

.favourite-tab {
  padding: 0.4rem 0.5rem;
}

.favourite-tab label {
  margin-left: 0.6rem;
}

.list-item:hover {
  background-color: #e6e6e6f5;
}

.sub-nav {
  position: absolute;
  top: 100px;
  right: 50px;
  z-index: 999;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(85, 80, 80, 0.526);
}

.sub-nav a:hover,
.sub-nav a.active {
  background-color: #ff9f43;
  color: #ffff;
}

.sub-nav a::after {
  background-color: #ff9f43;
}

.alert:empty {
  display: none !important;
}

.sub-detail {
  position: absolute;
  top: 150px;
  right: 50px;
  z-index: 999;
  width: 290px;
  max-height: 480px;
  overflow: auto;
  border: 1px solid rgba(85, 80, 80, 0.526);
}
.sub-detail p{
  margin: 0;
}

.back_button {
  font-size: 14px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.back-btn {
  text-align: center;
}

.back_button:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.status-closed::before {
  content: "\f06a";
  font-family: "Font awesome 6 pro";
  margin-right: 0.4rem;
  color: rgb(192, 14, 14);

}

.status-closed {
  color: rgb(192, 14, 14);
  font-size: 15px;
  text-transform: capitalize;
}

.status-open::before {
  content: "\f058";
  font-family: "Font awesome 6 pro";
  margin-right: 0.4rem;
  color: rgb(45, 153, 9);

}

.status-open {
  color: rgb(45, 153, 9);
  font-size: 15px;
  text-transform: capitalize;
}

.status-caution::before {
  content: "\f071";
  font-family: "Font awesome 6 pro";
  margin-right: 0.4rem;
  color: rgb(193, 166, 13);

}

.status-caution {
  color: rgb(193, 166, 13);
  font-size: 15px;
  text-transform: capitalize;
}

.content-paging h1 {
  margin-top: 60px;
  text-align: center;
}

.content-paging .content-paragraph {
  font-size: 18px;
}

#default-bound {
  position: absolute;
  top: 8px;
  z-index: 999;
  border: 1px solid rgb(0 0 0/ 30%);
  left: 86px;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 767px) {
  .sub-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    height: fit-content;
    top: auto;
    border: none;
  }

  .sub-detail {
    position: fixed;
    top: 0;
    right: -100%;
    transition: 0.3s;
    height: 100vh;
    width: 98%;
    max-height: 100%;
    z-index: 9990;
  }

  .sub-detail:has(#side-toggle:checked) {
    right: 0;
  }
}

.side-toggle {
  position: absolute;
  right: 10px;
  top: 10px;
}